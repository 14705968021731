'use strict';

import smoothscroll from 'smoothscroll-polyfill';
import '../scss/style.scss';
import './recaptcha';
import { Forms } from './forms.js';
import { Content } from './content.js';
import { SelectDropdown } from './select-dropdown.js';
import { Navigation } from './navigation.js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Analytics } from './analytics.js';
import { SchedulingAnalytics } from './analytics-scheduling';
import { Footer } from './appointments/footer.js';
import { initLottie, handleLocationCardViewAllHours, handleTableScroll } from './utils';
import { polyfill } from "seamless-scroll-polyfill";
require('webp-hero/dist-cjs/polyfills.js');
import { WebpMachine } from "webp-hero/dist-cjs";
import 'focus-visible';

// kick off the polyfill!
smoothscroll.polyfill();
polyfill();

class Main {
    constructor() {
        this.domReady();
    }

    domReady(event) {
        const webpMachine = new WebpMachine();
        webpMachine.polyfillDocument();

        const analytics = new Analytics();
        const schedulingAnalytics = new SchedulingAnalytics(analytics);

        const forms = new Forms;
        const content = new Content;
        const selectDropdown = new SelectDropdown;
        const navigation = new Navigation
        const footer = new Footer();

        const phoneNumbers = document.querySelectorAll(".phone-number");
        phoneNumbers.forEach((el) => {
            const number = el.getAttribute('data-attr-number');
            if (!number) {
                return;
            }
            const num = parsePhoneNumberFromString(number);
            el.text = num.formatNational();
        });

        initLottie();
        handleLocationCardViewAllHours();
        handleTableScroll();
    }
}

export default new Main();

if (module.hot) {
    module.hot.accept();
}