'use strict'

const Dimension_Active_Alert = 'Active_Alert';
const Dimension_Class_Event_Location = 'Class/Event_Location';
const Dimension_National_Physician_ID = 'National_Physician_ID';
const Dimension_Appointment_Service_Chosen = 'Appointment_Service_Chosen';
const Dimension_Patient_ID = 'Patient_ID';
const Dimension_Patient_Type = 'Patient_Type';
const Dimension_Scheduling_For = 'Scheduling_For';
const Dimension_Epic_Physician_ID = 'Epic_Physician_ID';
const Dimension_Physician_Rating = 'Physician_Rating';
const Dimension_Appointment_Type = 'Appointment_Type';
const Dimension_New_Patient_Record = 'Net_New_Patient_Record';
const Dimension_Schedule_Flow_Type = 'Schedule_Flow_Type';
const Dimension_Clearstep_Conversation_ID = 'Clearstep_Conversation_ID';
const Dimension_Service_ID = 'Service_ID';
const Dimension_Video_Visit_By_Provider_Or_By_Time = 'Video_Visit_By_Provider_or_By_Time';
const Dimension_Recommended_Provider_Flag = 'Recommended_Provider_Flag';

const Metric_Event_Registration_Complete_Count = 'Metric_Event_Registration_Complete_Count';
const Metric_Schedule_Appointment_Complete_Count = 'Metric_Schedule_Appointment_Count';
const Metric_New_Patient_Record_Complete_Count = 'Metric_Net_New_Patient_Count';
const Metric_Recommended_Provider_Appointment_Count = 'Metric_Reco_Provider_Appointment_Count';

export class Analytics {

    constructor(loadEvents = true) {
        if (loadEvents == true) {
            this.setupCustomData();
            window.dataLayer.push({
                event: 'dimension_info',
                attributes: this.customData
            });
            this.events();
            this.newsletterSubscribeStart();
            this.newsletterSubscribeComplete();
            this.needCareCTAs();
        }
    }

    setupCustomData(category, action, label, cb, classEventId) {
        this.customData = {};

        this.setupCustomDataActiveAlert();
        this.setupCustomDataClassEventLocation(category, classEventId);
        this.setupCustomDataNationalPhysicianID();
        this.setupCustomDataAppointmentServiceChosen();
        this.setupCustomDataPatientID();
        this.setupCustomDataPatientType();
        this.setupCustomDataSchedulingFor();
        this.setupCustomDataEpicPhysicianID();
        this.setupCustomDataPhysicianRating();
        this.setupCustomDataAppointmentType();
        this.setupCustomDataNewPatientRecord();
        this.setupCustomDataScheduleFlowType();
        this.setupCustomDataClearstepConversationID();
        this.setupCustomDataServiceID();
        this.setupDulyNowByTimeOrByProvider();
        this.setupCustomDataRecommendedProviderFlag();
    }

    setupCustomDataActiveAlert() {
        // Process alerts on the page
        const defaultAlerts = document.querySelectorAll(".body-alert-container div.emergency-alert.default:not(.hide)");
        const locationAlerts = document.querySelectorAll(".body-alert-container div.emergency-alert.location:not(.hide)")
        const hasDefaultAlerts = defaultAlerts.length != 0;
        const hasLocationAlerts = locationAlerts.length != 0;

        if (hasDefaultAlerts && hasLocationAlerts) {
            this.customData[Dimension_Active_Alert] = 'Both';
        } else if (hasDefaultAlerts && !hasLocationAlerts) {
            this.customData[Dimension_Active_Alert] = 'Global';
        } else if (!hasDefaultAlerts && hasLocationAlerts) {
            this.customData[Dimension_Active_Alert] = 'Local';
        }
    }

    setupCustomDataClassEventLocation(category, classEventId) {
        // Classes custom event
        if (category == "Classes and Events") {
            if (typeof classEventId == "undefined") {
                classEventId = "Location Not Defined";
            }

            this.customData[Dimension_Class_Event_Location] = classEventId;
        }
    }

    setupCustomDataNationalPhysicianID() {
        if (document.querySelector('[name="national-physician-id"]')) {
            this.customData[Dimension_National_Physician_ID] = document.querySelector('[name="national-physician-id"]').value;
        }
    }

    setupCustomDataAppointmentServiceChosen() {
        if (document.querySelector('[name="appointment-service-chosen"]')) {
            this.customData[Dimension_Appointment_Service_Chosen] = document.querySelector('[name="appointment-service-chosen"]').value;
        }
    }

    setupCustomDataPatientID() {
        const eid = document.querySelector("#PatientIdentifier");
        if (eid != null) {
            this.customData.user_id = eid.getAttribute("data-eid");
            this.customData[Dimension_Patient_ID] = eid.getAttribute("data-eid");
        }
    }

    setupCustomDataPatientType() {
        if (document.querySelector('[name="scheduling-for"]')) {
            this.customData[Dimension_Scheduling_For] = document.querySelector('[name="scheduling-for"]').value;
        }
    }

    setupCustomDataSchedulingFor() {
        if (document.querySelector('[name="patient-type"]')) {
            this.customData[Dimension_Patient_Type] = document.querySelector('[name="patient-type"]').value;
        }
    }

    setupCustomDataNewPatientRecord() {
        if (document.querySelector('[name="new-patient-record"]')) {
            this.customData[Dimension_New_Patient_Record] = 'New Patient Record';
            this.newPatientRecord = true;
        }
    }

    setupCustomDataEpicPhysicianID() {
        if (document.querySelector('[name="epic-physician-id"]')) {
            this.customData[Dimension_Epic_Physician_ID] = document.querySelector('[name="epic-physician-id"]').value;
        }
    }

    setupCustomDataPhysicianRating() {
        if (document.querySelector('[name="physician-rating"]')) {
            this.customData[Dimension_Physician_Rating] = document.querySelector('[name="physician-rating"]').value;
        } else {
            this.customData[Dimension_Physician_Rating] = "N/A";
        }
    }

    setupCustomDataAppointmentType() {
        if (document.querySelector('[name="appointment-type"]')) {
            this.customData[Dimension_Appointment_Type] = document.querySelector('[name="appointment-type"]').value;
        }
    }

    setupCustomDataScheduleFlowType() {
        if (document.querySelector('[name="schedule-flow-type"]')) {
            this.customData[Dimension_Schedule_Flow_Type] = document.querySelector('[name="schedule-flow-type"]').value;
        }
    }

    setupCustomDataClearstepConversationID() {
        if (document.querySelector('[name="clearstep-conversation-id"]')) {
            this.customData[Dimension_Clearstep_Conversation_ID] = document.querySelector('[name="clearstep-conversation-id"]').value;
        }
    }

    setupCustomDataServiceID() {
        if (document.querySelector('[name="service-id"]') && document.querySelector('[name="service-id"]').value) {
            this.customData[Dimension_Service_ID] = document.querySelector('[name="service-id"]').value;
        }
    }

    setupDulyNowByTimeOrByProvider() {
        if (document.querySelector('[name="duly-now-by-time-or-by-provider"]')) {
            this.customData[Dimension_Video_Visit_By_Provider_Or_By_Time] = document.querySelector('[name="duly-now-by-time-or-by-provider"]').value;
        }
    }

    setupCustomDataRecommendedProviderFlag() {
        const appointmentRecommendedPhysicianId = document.querySelector('[name="appointment_selected_recommended_physician_id"]');
        if (appointmentRecommendedPhysicianId && appointmentRecommendedPhysicianId.value) {
            this.userIsSchedulingWithRecommendedProvider = true;
            this.customData[Dimension_Recommended_Provider_Flag] = "Recommended Provider Chosen";
        }
    }

    newsletterSubscribeStart() {
        const subscribeButton = document.querySelector('.sign-up .newsletter');
        if (subscribeButton) {
            subscribeButton.addEventListener('click', _ => {
                this._sendEvent('newsletter_subscribe_start');
            });
        }
    }

    newsletterSubscribeComplete() {
        const subscribeButton = document.querySelector('#mc_embed_signup #mc-embedded-subscribe');
        if (subscribeButton) {
            subscribeButton.addEventListener('click', _ => {
                this._sendEvent('newsletter_subsribe_complete');
            });
        }
    }

    eventsSearch() {
        const clickEvents = ['click', 'keydown'];
        const button = document.querySelector('.event-listings .event-search-btn');
        const input = document.querySelector('.event-listings #search-events');

        clickEvents.forEach((event) => {
            [button, input].forEach(elem => {
                elem && elem.addEventListener(event, e => this.eventsSearchListener(e, event));
            });
        });
    }

    needCareCTAs() {
        // click events for the dropdown options
        document.querySelectorAll("#care-nav-mobile .care-dropdown-container-mobile .link a, #care-nav .care-dropdown-container .link a").forEach(element => {
            element.addEventListener('click', ({ target }) => {
                const link = target.closest('.link');
                if (!link) {
                    return;
                }
                const title = link.querySelector('.title');
                if (!title) {
                    return;
                }
                this._sendEvent('get_care_dropdown_choice', {
                    selection: title.innerText
                })
            });
        });
    }

    clearStepImmediateCareCheckSymptomsEvent() {
        this._sendEvent('clearstep_check_symptoms_immediate_care');
    }

    keydownOrClick(event, e) {
        if ((event === 'keydown' && e.keyCode === 13) || event === "click") {
            return true;
        } else {
            return false;
        }
    }

    events() {
        // Alerts
        const alerts = document.querySelectorAll('.body-alert-container div.emergency-alert:not(.hide):not(.immediate-care)');
        alerts.forEach((el) => {
            const closeBtn = el.querySelector("i.material-icons.close");
            if (closeBtn) {
                closeBtn.addEventListener('click', _ => {
                    const alertMessage =  closeBtn.closest('.container').querySelector('.message').innerText.replace('View More', '');
                    this._sendEvent('alert_close', {
                        alert_message: alertMessage
                    });
                });
            }

            const link = el.querySelector("a");
            if (link) {
                link.addEventListener('click', (e) => {
                    e.preventDefault();
                    const alertMessage =  el.querySelector('span.message').textContent;
                    this._sendEvent('alert_click', {
                        alert_message: alertMessage
                    })
                    if (!document.querySelector('.exit-alert-modal')) {
                        if (link.target === "_blank") {
                            window.open(link.href, '_blank');
                        } else {
                            document.location = link.getAttribute("href");
                        }
                    }
                });
            }
        });

        // Analytics related events
        const eventEntry = document.querySelector(".event-entry");
        if (eventEntry) {
            const eventType = eventEntry.getAttribute("data-event-type");
            const eventTitle = eventEntry.querySelector(".event-entry .main-content .headline h1").textContent;
            const eventId = eventEntry.getAttribute("data-event-id");

            const eventRegisterBtns = document.querySelectorAll(".event-details button.register");
            for (let eventRegisterBtn of eventRegisterBtns) {
                eventRegisterBtn.addEventListener("click", (e) => {
                    this._sendEvent('event_registration_start', {
                        event_type: eventType,
                        event_title: eventTitle,
                        event_id: eventId
                    })
                });
            }

            const phoneRegistrationLinks = document.querySelectorAll(".phone-registration a.phone-number");
            for (let phoneRegistrationLink of phoneRegistrationLinks) {
                phoneRegistrationLink.addEventListener("click", (e) => {
                    this._sendEvent('event_regiter_by_phone_click', {
                        event_type: eventType,
                        event_title: eventTitle,
                        event_id: eventId
                    })
                });
            }
        }

        const homepageHeroCtas = document.querySelectorAll(".hero-container a, #call-duly a");
        if (homepageHeroCtas) {
            homepageHeroCtas.forEach((cta) => {
                let ctaText = cta.innerText;
                let headerText = document.querySelector(".hero-container .text-container h1").innerText;
  
                cta.addEventListener("click", e => {
                    e.preventDefault();
                    this._sendEvent('home_page_hero_cta', {
                        cta_text: ctaText,
                        header_text: headerText 
                    })
                    window.location = cta.getAttribute('href');
                });
            })
        }

        const headerSearchForm = document.querySelector("#secondary-nav form.search");
        if (headerSearchForm) {
            headerSearchForm.addEventListener("submit", _ => {
                this._sendEvent("header_search");
            });
        }

        const headerSearchFormMobile = document.querySelector(".search-mobile form");
        if (headerSearchFormMobile) {
            headerSearchFormMobile.addEventListener("submit", _ => {
                this._sendEvent("header_search");
            });
        }

        const mainSearchForm = document.querySelector(".search-page .search-content form.site-wide-search");
        if (mainSearchForm) {
            mainSearchForm.addEventListener("submit", _ => {
                const href = new URL(window.location);
                let label = href.searchParams.get('section');
                label = label && label.length > 0 ? label : 'All';
                this._sendEvent("site_search");
            });
        }

        const mainSearchFormButton = document.querySelector(".search-page .search-content .search-results-btn");
        if (mainSearchFormButton) {
            mainSearchFormButton.addEventListener("click", _ => {
                const href = new URL(window.location);
                let label = href.searchParams.get('section');
                label = label && label.length > 0 ? label : 'All';
                this._sendEvent("site_search");
            });
        }

        // content share
        const facebookShare = document.querySelector('.article-contents .share-sheet .facebook');
        if (facebookShare) {
            facebookShare.addEventListener('click', _ => {
                this._sendEvent("content_share", {
                    share_method: "Facebook"
                })
            });
        }

        const twitterShare = document.querySelector('.article-contents .share-sheet .twitter');
        if (twitterShare) {
            twitterShare.addEventListener('click', _ => {
                this._sendEvent("content_share", {
                    share_method: "Twitter"
                })
            });
        }

        const emailShare = document.querySelector('.article-contents .share-sheet .mail');
        if (emailShare) {
            emailShare.addEventListener('click', _ => {
                this._sendEvent("content_share", {
                    share_method: "Email"
                })
            });
        }

    }

    EventRegisterCallback() {
        const eventEntry = document.querySelector(".event-entry");
        if (eventEntry) {
            const eventType = eventEntry.getAttribute("data-event-type");
            const eventTitle = eventEntry.querySelector(".event-entry .main-content .headline h1").textContent;
            const eventId = eventEntry.getAttribute("data-event-id");
            this._sendEvent('event_registration_complete', {
                event_type: eventType,
                event_title: eventTitle,
                event_id: eventId
            })
        }
    }

    EventRegisterFailureCallback(response) {
        const eventEntry = document.querySelector(".event-entry");
        if (eventEntry) {
            const eventType = eventEntry.getAttribute("data-event-type");
            const eventTitle = eventEntry.querySelector(".event-entry .main-content .headline h1").textContent;
            const eventId = eventEntry.getAttribute("data-event-id");
            this._sendEvent('event_registration_start', {
                event_type: eventType,
                event_title: eventTitle,
                event_id: eventId,
                error: JSON.stringify(response)
            })
        }
    }

    PhysicianSearch(data, actionName) {
        let labels = [];
        for (let [k, v] of Object.entries(data)) {
            if (k != "order_by" && k != "latitude" && k != "longitude") {
                labels.push(k);
            }
        };
        let label = labels.join(",");

        if (typeof data.order_by == "object") {
            label = label + ";" + data.order_by;
        }

        this._sendEvent("physician_search");
    }

    LocationSearch(data) {
        let labels = [];
        for (let [k, v] of Object.entries(data)) {
            if (k != "order_by" && k != "latitude" && k != "longitude") {
                labels.push(k);
            }
        };
        let label = labels.join(",");

        if (typeof data.order_by == "object") {
            label = label + ";" + data.order_by;
        }

        // this._sendEvent("Search", "Locations Search", label);
    }

    EventsSearch(data) {
        let labels = [];
        for (let [k, v] of Object.entries(data)) {
            if (k != "order_by" && k != "latitude" && k != "longitude") {
                labels.push(k);
            }
        };
        let label = labels.join(",");

        if (typeof data.order_by == "object") {
            label = label + ";" + data.order_by;
        }

        this._sendEvent("events_search");
    }

    /**
     * pushes event data into window.dataLayer
     * @param string category 
     * @param string action 
     * @param string label
     * @param function cb
     * @param string cel
     */
    _sendEvent(eventName, attributes = {})
    {
        if (eventName == "event_registration_complete") {
            attributes[Metric_Event_Registration_Complete_Count] = "1";
        }

        if (eventName == "schedule_appointment_complete" || eventName == "clearstep_appointment_complete" || eventName == "duly_now_video_visit_complete") {
            attributes[Metric_Schedule_Appointment_Complete_Count] = "1";
            // if a new patient was also created during this event, update new patient metric
            if (this.newPatientRecord) {
                attributes[Metric_New_Patient_Record_Complete_Count] = "1";
            }
            if (this.userIsSchedulingWithRecommendedProvider) {
                attributes[Metric_Recommended_Provider_Appointment_Count] = "1";
            }
        }

        window.dataLayer.push({
            event: eventName,
            attributes: attributes
        })
    }
}